export const compress = (_img, canvasId) => {
	return new Promise((resolve) => {
		const img = new Image()
		img.onload = function() {
			// 2.计算符合目标尺寸宽高值，若上传图片的宽高都大于目标图，对目标图等比压缩；如果有一边小于，对上传图片等比放大。
			var _goalWidth = 500, //目标宽度
			_goalHeight = 500, //目标高度
			_imgWidth = img.naturalWidth, //图片宽度
			_imgHeight = img.naturalHeight, //图片高度
			_tempWidth = _imgWidth, //放大或缩小后的临时宽度
			_tempHeight = _imgHeight, //放大或缩小后的临时宽度
			_r = 0; //压缩比

			if (_imgWidth > _goalWidth && _imgHeight > _goalHeight) { //宽高都大于目标图，需等比压缩
				_r = _imgWidth / _goalWidth;
				if (_imgHeight / _goalHeight < _r) {
					_r = _imgHeight / _goalHeight;
				}
				_tempWidth = Math.ceil(_imgWidth / _r);
				_tempHeight = Math.ceil(_imgHeight / _r);
			} else {
				if (_imgWidth < _goalWidth && _imgHeight < _goalHeight) { //宽高都小于
					_r = _goalWidth / _imgWidth;
					if (_goalHeight / _imgHeight < _r) {
						_r = _goalHeight / _imgHeight;
					}
				} else {
					if (_imgWidth < _goalWidth) { //宽小于
						_r = _goalWidth / _imgWidth;
					} else { //高小于
						_r = _goalHeight / _imgHeight;
					}
				}

				_tempWidth = Math.ceil(_imgWidth * _r);
				_tempHeight = Math.ceil(_imgHeight * _r);
			}

			//3.利用canvas对图片进行裁剪，等比放大或缩小后进行居中裁剪
			var _canvas = document.getElementById(canvasId);

			if (!_canvas.getContext) return;


			var _context = _canvas.getContext('2d');
			_canvas.width = _tempWidth;
			_canvas.height = _tempHeight;
			_context.drawImage(img, 0, 0, _tempWidth, _tempHeight);

			//toDataURL方法，可以获取格式为"data:image/png;base64,***"的base64图片信息；
			var _data = _canvas.toDataURL('image/jpeg');

			base64ToBlob(_data, resolve)

		}
		img.src = _img
	})
}

const base64ToBlob = (_data, resolve) => {

	//window.atob方法将其中的base64格式的图片转换成二进制字符串；若将转换后的值直接赋值给Blob会报错，需Uint8Array转换：最后创建Blob对象；
	const type = _data.split(",")[0].split(";")[0].split(":")[1];
	_data = _data.split(',')[1]; // 去除掉前面的格式
	_data = window.atob(_data);



	//如果不用ArrayBuffer,发送给服务器的图片格式是[object Uint8Array],上传失败...
	let _buffer = [];
	for (let i = 0; i < _data.length; i++) {
		_buffer.push(_data.charCodeAt(i));
	}

	// 安卓 UC浏览器不支持 new Blob()，使用BlobBuilder
	var _blob;
	try {
		_blob = new Blob([new Uint8Array(_buffer)], {
			type: 'image/jpeg'
		});
	} catch (ee) {
		var BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
		if (ee.name == 'TypeError' && window.BlobBuilder) {
			var _bb = new BlobBuilder();
			_bb.append(_buffer);
			_blob = _bb.getBlob('image/jpeg');
		}
	}

	const file = new File([_blob], new Date().getTime() + "." + type, {
		type: type
	});

	resolve(file)
}
